// extracted by mini-css-extract-plugin
export var lbContainerOuter = "B_vb";
export var lbContainerInner = "B_vc";
export var movingForwards = "B_vd";
export var movingForwardsOther = "B_vf";
export var movingBackwards = "B_vg";
export var movingBackwardsOther = "B_vh";
export var lbImage = "B_vj";
export var lbOtherImage = "B_vk";
export var prevButton = "B_vl";
export var nextButton = "B_vm";
export var closing = "B_vn";
export var appear = "B_vp";